<template>
  <v-navigation-drawer v-model="drawer" app :mini-variant.sync="mini" hide-overlay :permanent="$vuetify.breakpoint.mdAndUp">
    <v-list>
      <v-list-item class="px-2">
        <v-list-item-avatar class='ml-0' :class="{'custom-avatar' : !mini, 'ml-0' : mini}" tile>
          <v-img :src="`${logo}`" v-if="!mini"></v-img>
          <v-img :src="`${favicon}`" v-else></v-img>
        </v-list-item-avatar>
        <span class="ml-n4"> <strong> YAYA MAIN ALTAR </strong> </span>
      </v-list-item>
    </v-list> 

    <v-list class="mt-5">
      <v-list-item-group v-model="selectedMenu">
        <template  v-for="(item, i) in items">
            <links :key="i" :item="item" :index="i" />
          </template>
      </v-list-item-group>
    </v-list>

  </v-navigation-drawer>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';

export default {
  name: 'sideBarComponent',
  components: {
    links: () => import('@/components/navigationLinks')
  },
  data() {
    return {
      mini: false,
      favicon: require('../assets/logo.webp'),
      logo: require('../assets/logo.webp'),
      drawer: true,
      selectedMenu: 2,
      items: [
        {
          icon: 'mdi-account',
          text: 'Register Member',
          link: '/',
          permission: 'register'
        },
        {
          icon: 'mdi-cloud-upload',
          text: 'Upload Excel',
          link: '/upload',
          permission: 'upload'
        },
        {
          icon: 'mdi-star',
          text: 'Reports',
          link: '/reports',
          permission: 'reports'
        },
        {
          icon: 'mdi-send',
          text: 'SMS',
          link: '/sms',
          permission: 'sms',
        },
        {
          icon: 'mdi-account',
          text: 'User Management',
          link: '/user-management',
          permission: 'user-management',
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['getDrawerStatus']),
    permanent() {
      return !this.drawer;
    },

  },
  watch: {
    getDrawerStatus(status) {
      this.drawer = status;
      this.mini = !status;
    },
  },
  mounted() {
    this.drawer = this.getDrawerStatus;
  },
  methods: {
    ...mapMutations(['setDrawerStatus']),
    close() {
      this.mini = true;
      this.drawer = false;
      this.setDrawerStatus(false);
    },
  },
}
</script>

<style scoped>
.custom-avatar.v-avatar {
  height: 110px !important;
  min-width: 48px !important;
  width: 120px !important;
  background: #ffffff;
  }
.nav-links {
  background: #D3DDF6 !important;
  border-radius: 6px;
  color: #324BA8 !important;
}
</style>